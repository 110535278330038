import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import {
    pictureListContainer,
    pictureLink,
    pictureContainer,
    picture,
    squarePicture,
    bottomContainer,
    displayBottomText,
    altText,
    credit,
    creditIcon
} from "./picture-list.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { pictureComparator } from "../utils/picture-util"


const PictureList = ({ pictures, displayText, displaySource, square }) => {
    pictures.sort(pictureComparator)
    return (
        <div className={pictureListContainer}>

            {pictures
                .filter((node) => node.file && node.file.childImageSharp)
                .map((node) =>
                    <a className={pictureLink}
                       key={node.id}
                       href={node.file.publicURL}
                       target="_blank"
                       rel="noopener noreferrer">
                        <div className={pictureContainer + (square ? " "+ squarePicture : "")}>
                            <GatsbyImage
                                image={node.file.childImageSharp.gatsbyImageData}
                                placeholder="blurred"
                                className={picture}
                                objectFit="cover"
                                objectPosition={"50%" + (node.y !== null ? node.y + "%" : "50%")}
                                alt={node.alt || ""}/>
                            <div className={bottomContainer + (displayText ? " " + displayBottomText : "")}>
                                <div className={altText}>
                                    {node.alt}
                                </div>
                                {node.credit && <div className={credit}>
                                    <FontAwesomeIcon icon={faCamera}
                                                     className={creditIcon}
                                                     alt={"Photograph by "}/>
                                    {node.credit}
                                </div>}

                            </div>
                        </div>
                    </a>)}

        </div>
    )
}
PictureList.propTypes = {
    pictures: PropTypes.array.isRequired
}
export default PictureList


export const query = graphql`
    fragment PictureFragment on PicturesJson {
        id
        credit
        talk
        alt
        y
        priority
        file {
            id
            name
            publicURL
            childImageSharp {
                gatsbyImageData(
                    width: 660,
                    placeholder: BLURRED,
                    formats: [AUTO, PNG, WEBP],
                    layout: CONSTRAINED)
            }
        }
    }
`
