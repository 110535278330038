import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

import Button from "../components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getTalkTypeName } from "../utils/talk-util"
import NewlineText from "../components/newline-text"

import PeopleList from "../components/people-list"
import PaperList from "../components/paper-list"
import ProjectList from "../components/project-list"


import { faSlideshare } from "@fortawesome/free-brands-svg-icons"
import {
    faArrowLeft,
    faCalendarDay,
    faChalkboardTeacher,
    faFilePdf,
    faComment,
    faGlobe, faPenNib, faQuoteLeft, faRocket, faUser, faVideo, faCamera, faLink, faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons"
import {
    talkMetadata,
    logoContainer,
    locationLogo,
    bottomViewButtonContainer,
    videoEmbedding,
    slideEmbedding
} from "./talk.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialMediaPostList from "../components/social-media-post-list"
import PictureList from "../components/picture-list"
import Breadcrumb from "../components/breadcrumb"
import { getMainPicture } from "../utils/picture-util"


function isYoutubeVideo(videoUrl) {
    return videoUrl.startsWith("https://youtube.com") || videoUrl.startsWith("https://www.youtube.com")
}

function getYoutubeEmbedUrl(videoUrl) {
    return videoUrl.replace("youtube.com/watch?v=", "youtube-nocookie.com/embed/")
}

const TalkPage = ({ data }) => {

    const talk = data.allFile.edges[0].node.childTalksJson

    const projects = talk.relatedProjects
    const papers = talk.relatedPapers

    const pressLogo = talk.logoImage
    const pressLogoImage = pressLogo && pressLogo.childImageSharp && getImage(pressLogo.childImageSharp.largeGatsbyImageData)

    const mainPicture = !!talk.relatedPictures && !!talk.relatedPictures.length && getMainPicture(talk.relatedPictures)

    return <>
        <Seo title={talk.title}
             image={(mainPicture && mainPicture.file && mainPicture.file.publicURL) || (talk.fields && talk.fields.slideshareFirstSlide) || (pressLogo && pressLogo.publicURL)}
             largeImg={!!mainPicture || (talk.fields && talk.fields.slideshareFirstSlide)}
             description={talk.text}/>
        <Breadcrumb pages={[{
            name: "Thomas Winters",
            link: "/"
        }, {
            name: "Appearances",
            link: "/appearances"
        }, {
            name: "Talks",
            link: "/appearances#talks"
        },
            {
                name: talk.title
            }
        ]}/>
        <h1>{talk.title}</h1>
        <section className={talkMetadata}>
            {pressLogoImage && <div className={logoContainer}>
                <GatsbyImage className={locationLogo}
                             image={pressLogoImage}
                             alt={talk.location + " Logo"}
                             placeholder="blurred"/></div>}
            <ul className="fa-ul">
                <li>
                    <FontAwesomeIcon icon={faUser} listItem/>
                    <strong>Speaker{talk.speakersPeople.length > 1 ? "s" : ""}: </strong>
                    <PeopleList people={talk.speakersPeople}/>
                </li>
                <li>
                    <FontAwesomeIcon icon={faChalkboardTeacher} listItem/>
                    <strong>Type: </strong>
                    {getTalkTypeName(talk.type)}
                </li>
                <li>
                    <FontAwesomeIcon icon={faCalendarDay} listItem/>
                    <strong>Date: </strong>
                    {talk.date}
                </li>
                <li>
                    <FontAwesomeIcon icon={faGlobe} listItem/>
                    <strong>Location: </strong>
                    {talk.url &&
                    <a href={talk.url} target="_blank" rel="noopener noreferrer">
                        <span>
                            {talk.location}
                            {talk.locationExtra ? ": " : ""}
                        </span>
                        <span>
                            {talk.locationExtra && <em>{talk.locationExtra}</em>}
                        </span>
                    </a>
                    }
                    {!talk.url &&
                        <>
                            <span>
                                {talk.location}
                                {talk.locationExtra ? ": " : ""}
                            </span>
                            <span>
                                {talk.locationExtra && <em>{talk.locationExtra}</em>}
                            </span>
                        </>
                    }
                </li>
                {talk.text && <li>
                    <FontAwesomeIcon icon={faQuoteLeft} listItem/>
                    {/*<strong>Summary: </strong>*/}
                    <NewlineText text={talk.text}/>
                </li>
                }
                {
                    talk.links && <li>
                        <FontAwesomeIcon icon={faLink} listItem/>
                        <strong>Links: </strong>
                        <ul className="fa-ul">
                            {talk.links.map(link =>
                                <li>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} listItem/>
                                    <a href={link.url}
                                       className="underline"
                                       target="_blank"
                                       rel="noopener noreferrer">{link.title}</a>
                                    {link.description && <span>: {link.description}</span>}
                                </li>)}
                        </ul>
                    </li>
                }
            </ul>

        </section>


        {talk.video && isYoutubeVideo(talk.video) &&
            <section>
                <h2>
                    <FontAwesomeIcon icon={faVideo} className={"fa-fw header-icon"} size={"sm"}/>
                    Video
                </h2>
                <div className={videoEmbedding}>
                    <iframe src={getYoutubeEmbedUrl(talk.video)}
                            width="560" height="349"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </section>}
        {talk.video && !isYoutubeVideo(talk.video) &&
            <section className={bottomViewButtonContainer}>
                <Button icon={faFilePdf}
                        link={talk.video}
                        type={"primary"}
                        text={"View talk"}
                        size={"lg"}/>
            </section>}

        {talk.slides &&
            <section>
                <h2>
                    <FontAwesomeIcon icon={faSlideshare} className={"fa-fw header-icon"} size={"sm"}/>
                    Slides
                </h2>
                {talk.fields && talk.fields.slideshareEmbedUrl &&
                    <div className={slideEmbedding}>
                        <iframe src={talk.fields.slideshareEmbedUrl} width="427"
                                title="Slideshare"
                                height="356" frameBorder="0" marginWidth="0"
                                marginHeight="0"
                                scrolling="no"
                                allowFullScreen></iframe>
                    </div>}


                <div className={bottomViewButtonContainer}>
                    <Button icon={faSlideshare}
                            link={talk.slides}
                            type={"primary"}
                            text={"View" + (talk.slides && talk.slides.indexOf("https://www.slideshare.net")>0 ? " on SlideShare" : " slides")}/>

                </div>
            </section>}

        {papers && papers.length > 0 && <section>
            <h2>
                <FontAwesomeIcon icon={faPenNib} className={"fa-fw header-icon"} size={"sm"}/>
                Related paper{papers.length > 1 ? "s" : ""}
            </h2>
            <PaperList papers={papers}/>
        </section>}

        {talk.relatedPictures.length > 0 && <section>
            <h2>
                <FontAwesomeIcon icon={faCamera} className={"fa-fw header-icon"} size={"sm"}/>
                Pictures
            </h2>
            <PictureList pictures={talk.relatedPictures}/>
        </section>}

        {talk.relatedSocialMediaPosts.length > 0 && <section>
            <h2>
                <FontAwesomeIcon icon={faComment} className={"fa-fw header-icon"} size={"sm"}/>
                What people said
            </h2>
            <SocialMediaPostList socialMediaPosts={talk.relatedSocialMediaPosts}/>
        </section>}

        {projects && projects.length > 0 && <section>
            <h2>
                <FontAwesomeIcon icon={faRocket} className={"fa-fw header-icon"} size={"sm"}/>
                Related projects
            </h2>
            <ProjectList projects={projects}/>
        </section>}


        <section>
            <Button icon={faArrowLeft}
                    link={"/appearances#talks"}
                    local={true}
                    text={"Back to all talks"}/>
        </section>
    </>
}

export default TalkPage

export const query = graphql`query ($json: String!) {
    allFile(filter: {relativePath: {regex: $json}}) {
        edges {
            node {
                id
                childTalksJson {
                    date
                    links {
                        title
                        url
                        description
                    }
                    location
                    locationExtra
                    logo
                    papers
                    projects
                    title
                    text
                    type
                    url
                    video
                    slides
                    speakers
                    speakersPeople {
                        ...PeopleFragment
                    }
                    logoImage {
                        id
                        base
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                width: 58
                                placeholder: BLURRED
                                formats: [AUTO, PNG, WEBP, AVIF]
                            )
                            largeGatsbyImageData:gatsbyImageData(
                                width: 256
                                placeholder: BLURRED
                                formats: [AUTO, PNG, WEBP, AVIF]
                            )
                        }
                    }
                    fields {
                        slideshareEmbedUrl
                        slideshareFirstSlide
                        path
                        name
                    }
                    relatedPictures {
                        ...PictureFragment
                    }
                    relatedSocialMediaPosts {
                        ...SocialMediaPostFragment
                    }
                    relatedPapers {
                        ...PaperFragment
                    }
                    relatedProjects {
                        ...ProjectOverviewFragment
                    }
                }
            }
        }
    }
}
`
