// extracted by mini-css-extract-plugin
export var altText = "picture-list-module--alt-text--66a0c";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var bottomContainer = "picture-list-module--bottom-container--12cda";
export var credit = "picture-list-module--credit--d64de";
export var creditIcon = "picture-list-module--credit-icon--d5b76";
export var displayBottomText = "picture-list-module--display-bottom-text--7158f";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var picture = "picture-list-module--picture--6e39a";
export var pictureContainer = "picture-list-module--picture-container--ca993";
export var pictureLink = "picture-list-module--pictureLink--ce67c";
export var pictureListContainer = "picture-list-module--picture-list-container--75847";
export var squarePicture = "picture-list-module--square-picture--fa8cc";