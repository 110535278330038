const pictureComparator = (a, b) => {
    const aPriority = a.priority || 0
    const bPriority = b.priority || 0
    if (aPriority !== bPriority) {
        return bPriority - aPriority
    }

    return a.file.name < b.file.name ? -1 : 1
}

const getMainPicture = function(pictures) {
    if (!pictures.length) {
        return
    }
    return pictures.reduce(function(prev, curr) {
        return pictureComparator(prev, curr) < 0 ? prev : curr
    })
}

module.exports = { getMainPicture, pictureComparator }

